import React from 'react'
import { Box } from '@mui/material'
import clsx from 'clsx'
import PdfViewer from 'src/components/molecules/PdfViewer'
import ZoomableReceiptImage, {
  ZoomableReceiptImageProps,
} from '../../../../components/molecules/ZoomableReceiptImage'

import TenantSalesReportTable, {
  TenantSalesReportTableProps,
} from '../TenantSalesReportTable'
import styles from './styles.module.scss'
import { RoleEnum } from '../../../../slices/services/api'
import { isOneOfRoles, ShowSalesValueTableRoles } from '../../../../domain/role'

export type TenantSalesReportRegisterProps = React.StyledProps &
  TenantSalesReportTableProps &
  ZoomableReceiptImageProps & {
    isPdf?: boolean
    isFirst?: boolean
    onLoaded?: () => void
    role?: RoleEnum
  }

const TenantSalesReportRegister: React.StyledFC<
  TenantSalesReportRegisterProps
> = ({
  className,
  concatOriginal,
  separatedOriginal,
  resized,
  registerIndex,
  columns,
  rows,
  role,
  isPdf = false,
  isFirst,
  onLoaded,
  onClickValue,
}: TenantSalesReportRegisterProps) => {
  return (
    <Box className={clsx(className, styles.root)}>
      <Box className={styles.wrapper}>
        <Box className={isPdf ? styles.pdfWrapper : styles.receiptWrapper}>
          {isPdf ? (
            <PdfViewer
              className={styles.pdf}
              separatedOriginal={separatedOriginal}
            />
          ) : (
            <ZoomableReceiptImage
              className={styles.receipt}
              {...{
                concatOriginal,
                separatedOriginal,
                resized,
                onLoaded,
              }}
            />
          )}
        </Box>
        {isOneOfRoles(ShowSalesValueTableRoles, role) && (
          <Box
            className={clsx(styles.tableWrapper, isFirst && styles.topMargin)}
          >
            <TenantSalesReportTable
              className={styles.table}
              {...{ columns, rows, registerIndex, onClickValue }}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default TenantSalesReportRegister
